// @ts-nocheck
import React, { useState } from "react";
import {
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  Box,
  IconButton,
  MenuItem,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import BasicDialogue from "./BasicDialogue";
import { days } from "./AddLicenseModal";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  select: {
    paddingLeft: 12,
  },
}));

export default function ExtendLicenses({ show, handleClose, item, extendActivatedExpired }) {
  const classes = useStyles();

  const defaulDays =
    days.find(({ value }) => value === item.days)?.value || 365;

  const [daysValue, setDaysValue] = useState(defaulDays);

  const handleClearForm = () => {
    setDaysValue(defaulDays);
    handleClose();
  };

  const handleChangeDays = (event) => {
    setDaysValue(event.target.value);
  };

  const Content = (
    <Grid container spacing={2} direction="column">
      <Grid item xs>
        <FormControl style={{ width: "100%" }}>
          <InputLabel id="demo-simple-select-label">Days</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={daysValue}
            onChange={handleChangeDays}
            classes={{ root: classes.select }}
            endAdornment={
              daysValue !== defaulDays && (
                <Box mr="15px">
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      setDaysValue(defaulDays);
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                </Box>
              )
            }
          >
            {days.map((item) => (
              <MenuItem key={item.name} value={item.value}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );

  const Action = (
    <>
      <Button variant="outlined" color="primary" onClick={handleClearForm}>
        Cancel
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={() => extendActivatedExpired(item.id, daysValue)}
      >
        Submit
      </Button>
    </>
  );

  return (
    <>
      <BasicDialogue
        show={show}
        handleClose={handleClose}
        title="Extend Key"
        Content={Content}
        Action={Action}
      />
    </>
  );
}
